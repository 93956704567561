import { FC, Fragment } from 'react';

const Background: FC = () => {
  return (
    <Fragment>
      <div
        className="noise-bg"
        style={{ backgroundImage: `url("/assets/img/bg/noise_bg.png")` }}
      />
      <div
        className="main-shape"
        style={{
          backgroundImage: `url("/assets/img/images/main_shape.png")`,
        }}
      />
    </Fragment>
  );
};

export default Background;
