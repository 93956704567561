import { Link } from 'gatsby';
import { FC, Fragment } from 'react';

type Props = {
  handleMobileMenu: () => void;
};

const MenuMobile: FC<Props> = ({ handleMobileMenu }) => {
  return (
    <Fragment>
      <div className="mobile-menu">
        <nav className="menu-box">
          <div className="close-btn" onClick={handleMobileMenu}>
            <i className="fas fa-times" />
          </div>
          <div className="nav-logo" onClick={handleMobileMenu}>
            <Link to="/">
              <img src="/assets/img/logo/logo.png" alt="Logo" />
            </Link>
          </div>
          <div className="menu-outer">
            <ul className="navigation">
              <li
                className={`menu-item-has-children tg-mega-menu-has-children`}
              >
                <Link
                  onClick={handleMobileMenu}
                  to="/"
                  activeClassName="active"
                >
                  <h5 className="mb-0">Home</h5>
                </Link>
              </li>
              <li
                className={`menu-item-has-children tg-mega-menu-has-children`}
              >
                <Link
                  onClick={handleMobileMenu}
                  to="/about"
                  activeClassName="active"
                >
                  <h5 className="mb-0">About</h5>
                </Link>
              </li>
              <li
                className={`menu-item-has-children tg-mega-menu-has-children`}
              >
                <Link
                  onClick={handleMobileMenu}
                  to="/frequently-asked-questions"
                  activeClassName="active"
                >
                  <h5 className="mb-0">FAQs</h5>
                </Link>
              </li>

              <li
                className={`menu-item-has-children tg-mega-menu-has-children`}
              >
                <Link
                  onClick={handleMobileMenu}
                  to="/how-it-works"
                  activeClassName="active"
                >
                  <h5 className="mb-0">How it works</h5>
                </Link>
              </li>

              <li
                className={`menu-item-has-children tg-mega-menu-has-children`}
              >
                <Link
                  onClick={handleMobileMenu}
                  to="/contact-us"
                  activeClassName="active"
                >
                  <h5 className="mb-0">Contact</h5>
                </Link>
              </li>
            </ul>
          </div>
          <div className="social-links">
            <ul className="clearfix list-wrap">
              <li>
                <Link to="#">
                  <i className="fab fa-facebook-f" />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <i className="fab fa-twitter" />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <i className="fab fa-instagram" />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <i className="fab fa-linkedin-in" />
                </Link>
              </li>
              <li>
                <Link to="#">
                  <i className="fab fa-youtube" />
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      <div className="menu-backdrop" onClick={handleMobileMenu} />
    </Fragment>
  );
};

export default MenuMobile;
