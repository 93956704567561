import { Link } from 'gatsby';
import { FC, useEffect, useState } from 'react';
import MenuMobile from './mobile-menu';

const Header: FC = () => {
  const [scroll, setScroll] = useState(0);
  const [isMobileMenu, setMobileMenu] = useState(false);

  useEffect(() => {
    document.addEventListener('scroll', () => {
      const scrollCheck = window.scrollY > 100;
      // @ts-ignore
      if (scrollCheck !== scroll) {
        // @ts-ignore
        setScroll(scrollCheck);
      }
    });

    const win = window as any;

    const WOW = require('wowjs');
    win.wow = new WOW.WOW({
      live: false,
    });
    win.wow.init();
  }, []);

  const handleMobileMenu = () => {
    setMobileMenu(!isMobileMenu);
    !isMobileMenu
      ? document.body.classList.add('mobile-menu-visible')
      : document.body.classList.remove('mobile-menu-visible');
  };

  return (
    <header>
      <div
        id="sticky-header"
        className={`menu-area transparent-header ${
          scroll ? 'sticky-menu' : ''
        }`}
      >
        <div className="container custom-container">
          <div className="row">
            <div className="col-12">
              <div className="mobile-nav-toggler" onClick={handleMobileMenu}>
                <i className="fas fa-bars" />
              </div>
              <div className="menu-wrap">
                <nav className="menu-nav">
                  <div className="logo">
                    <Link to="/">
                      <img
                        src="/assets/img/logo/logo.png"
                        alt="Logo"
                        height={40}
                      />
                    </Link>
                  </div>
                  <div className="navbar-wrap main-menu d-none d-lg-flex">
                    <ul className="navigation">
                      <li>
                        <Link to="/" activeClassName="active" role="li">
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link to="/about" activeClassName="active" role="li">
                          About
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/frequently-asked-questions"
                          activeClassName="active"
                          role="li"
                        >
                          FAQ
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/how-it-works"
                          activeClassName="active"
                          role="li"
                        >
                          How it works
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/contact-us"
                          activeClassName="active"
                          role="li"
                        >
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <MenuMobile handleMobileMenu={handleMobileMenu} />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export default Header;
