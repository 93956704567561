import type { GatsbyBrowser } from 'gatsby';
import React from 'react';
import Layout from './src/layout';
import './static/assets/css/style.css';
//
import './static/assets/css/responsive.css';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => {
  return <Layout>{element}</Layout>;
};
